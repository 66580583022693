// 모달
export const TYPE = {
    // 모달
    OPEN_MODAL: 'OPEN_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',
    OPEN_CONFIRM_MODAL: 'OPEN_CONFIRM_MODAL',
    CLOSE_CONFIRM_MODAL: 'CLOSE_CONFIRM_MODAL',
    OPEN_SLIDEUP: 'OPEN_SLIDEUP',
    CLOSE_SLIDEUP: 'CLOSE_SLIDEUP',
    // 로그인
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    // 로그아웃
    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',
    // 유저 조회
    USER_LOADING_REQUEST: 'USER_LOADING_REQUEST',
    USER_LOADING_SUCCESS: 'USER_LOADING_SUCCESS',
    USER_LOADING_FAILURE: 'USER_LOADING_FAILURE',
    // 회원가입
    USER_REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
    USER_REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
    USER_REGISTER_FAILURE: 'USER_REGISTER_FAILURE',
    // 게시글 조회
    POSTS_LOADING_REQUEST: 'POST_LOADING_REQUEST',
    POSTS_LOADING_FAILURE: 'POST_LOADING_FAILURE',
    POSTS_LOADING_SUCCESS: 'POST_LOADING_SUCCESS',
    POST_DETAIL_RESET: 'POST_DETAIL_RESET',
    // 게시글 작성
    POSTS_WRITE_REQUEST: 'POST_WRITE_REQUEST',
    POSTS_WRITE_FAILURE: 'POST_WRITE_FAILURE',
    POSTS_WRITE_SUCCESS: 'POST_WRITE_SUCCESS',
    // POST UPLOAD
    POST_UPLOADING_REQUEST: 'POST_UPLOADING_REQUEST',
    POST_UPLOADING_FAILURE: 'POST_UPLOADING_FAILURE',
    POST_UPLOADING_SUCCESS: 'POST_UPLOADING_SUCCESS',
    // POST Detail loading
    POST_DETAIL_LOADING_REQUEST: 'POST_DETAIL_LOADING_REQUEST',
    POST_DETAIL_LOADING_FAILURE: 'POST_DETAIL_LOADING_FAILURE',
    POST_DETAIL_LOADING_SUCCESS: 'POST_DETAIL_LOADING_SUCCESS',
    // POST LIKES
    POST_LIKE_REQUEST: 'POST_LIKE_REQUEST',
    POST_LIKE_FAILURE: 'POST_LIKE_FAILURE',
    POST_LIKE_SUCCESS: 'POST_LIKE_SUCCESS',
    // POST DELETE
    POST_DELETE_REQUEST: 'POST_DELETE_REQUEST',
    POST_DELETE_FAILURE: 'POST_DELETE_FAILURE',
    POST_DELETE_SUCCESS: 'POST_DELETE_SUCCESS',
    // Post Loading Edit
    POST_EDIT_LOADING_REQUEST: 'POST_EDIT_LOADING_REQUEST',
    POST_EDIT_LOADING_FAILURE: 'POST_EDIT_LOADING_FAILURE',
    POST_EDIT_LOADING_SUCCESS: 'POST_EDIT_LOADING_SUCCESS',

    // Post Uploading Edit
    POST_EDIT_UPLOADING_REQUEST: 'POST_EDIT_UPLOADING_REQUEST',
    POST_EDIT_UPLOADING_FAILURE: 'POST_EDIT_UPLOADING_FAILURE',
    POST_EDIT_UPLOADING_SUCCESS: 'POST_EDIT_UPLOADING_SUCCESS',

    // Post Uploading Edit
    CATEGORY_FIND_REQUEST: 'CATEGORY_FIND_REQUEST',
    CATEGORY_FIND_FAILURE: 'CATEGORY_FIND_FAILURE',
    CATEGORY_FIND_SUCCESS: 'CATEGORY_FIND_SUCCESS',

    // Post Search
    SEARCH_REQUEST: 'SEARCH_REQUEST',
    SEARCH_FAILURE: 'SEARCH_FAILURE',
    SEARCH_SUCCESS: 'SEARCH_SUCCESS',

    // Comment loading
    COMMENT_LOADING_REQUEST: 'COMMENT_LOADING_REQUEST',
    COMMENT_LOADING_FAILURE: 'COMMENT_LOADING_FAILURE',
    COMMENT_LOADING_SUCCESS: 'COMMENT_LOADING_SUCCESS',

    // Comment uploading
    COMMENT_UPLOADING_REQUEST: 'COMMENT_UPLOADING_REQUEST',
    COMMENT_UPLOADING_FAILURE: 'COMMENT_UPLOADING_FAILURE',
    COMMENT_UPLOADING_SUCCESS: 'COMMENT_UPLOADING_SUCCESS',

    // 에러 클리어
    CLEAR_ERROR_REQUEST: 'CLEAR_ERROR_REQUEST',
    CLEAR_ERROR_SUCCESS: 'CLEAR_ERROR_SUCCESS',
    CLEAR_ERROR_FAILURE: 'CLEAR_ERROR_FAILURE',
    // 에러 클리어
    LOADING_REQUEST: 'LOADING_REQUEST',
    LOADING_SUCCESS: 'LOADING_SUCCESS',
    LOADING_FAILURE: 'LOADING_FAILURE',

    VIEWS_CHECK_REQUEST: 'VIEWS_CHECK_REQUEST',
    VIEWS_CHECK_SUCCESS: 'VIEWS_CHECK_SUCCESS',
    VIEWS_CHECK_FAILURE: 'VIEWS_CHECK_FAILURE',
};
